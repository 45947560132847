<template>
    <div style="padding: 10px">
        <b v-if="matricola">Matricola: {{ matricola }}</b>
        <span v-if="reparto">
            <br>
            Reparto: {{ reparto }}
        </span>
    </div>
</template>

<script>
import apiEvacuatori from '@/js/pages/evacuatori';
export default {
    name: 'infoEvacuatore',
    props: [
        'id_evacuatore',
    ],
    watch: {
        async id_evacuatore() {
            await this.initInfoEvacuatore();
        }
    },
    data() {
        return {
            token: this.$cookies.get('token'),
            matricola: undefined,
            reparto: undefined
        }
    },
    async created() {
        await this.initInfoEvacuatore();
    },
    methods: {
        async initInfoEvacuatore() {
            if (this.id_evacuatore) {
                let evacuatore = await apiEvacuatori.getOne(this.token, this.id_evacuatore);
                if (evacuatore) {
                    if (evacuatore.matricola) this.matricola = evacuatore.matricola;
                    if (evacuatore.nome_reparto) this.reparto = evacuatore.nome_reparto;
                }
            }
        }
    }
}
</script>